<template>
  <validation-observer ref="simpleRules">
    <b-card-code title="Form Edit Arsip">
      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Subjek"
            rules="required"
          >
            <b-form-group
              label="Subjek"
              label-for="Subjek"
            >
              <b-form-input
                id="Subjek"
                v-model="Subjek"
                placeholder="Tulis Subjek"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="No. Surat"
            rules="required"
          >
            <b-form-group
              label="No. Surat"
              label-for="No. Surat"
            >
              <b-form-input
                id="No. Surat"
                v-model="NoSurat"
                placeholder="Tulis No. Surat"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Lokasi"
            rules="required"
          >
            <b-form-group
              label="Lokasi"
              label-for="Lokasi"
            >
              <b-form-input
                id="Lokasi"
                v-model="Lokasi"
                placeholder="Tulis Lokasi"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Kategori"
            rules="required"
          >
            <b-form-group
              label="Kategori"
              label-for="Kategori"
            >
              <v-select
                v-model="Kategori"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                placeholder="Pilih Kategori"
                :options="optionsKategori"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Waktu"
            rules="required"
          >
            <b-form-group
              label="Waktu"
              label-for="Waktu"
            >
              <b-form-datepicker
                id="Waktu"
                v-model="Waktu"
                locale="id"
                class="mb-1"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Deadline"
            rules="required"
          >
            <span
              v-show="Waktu === null "
              class="warning-right"
            > Pilih Waktu terlebih dahulu </span>
            <b-form-group
              label="Deadline"
              label-for="Deadline"
            >
              <b-form-datepicker
                id="Deadline"
                v-model="Deadline"
                :min="Waktu"
                :disabled="Waktu == null"
                locale="id"
                class="mb-1"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Surat Diterima"
            rules="required"
          >
            <b-form-group
              label="Surat Diterima"
              label-for="Surat Diterima"
            >
              <b-form-datepicker
                id="Surat Diterima"
                v-model="ReceivedDate"
                locale="id"
                class="mb-1"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Keterangan"
          >
            <b-form-group
              label="Keterangan"
              label-for="Keterangan"
            >
              <b-form-input
                id="Keterangan"
                v-model="note"
                placeholder="Tulis Keterangan"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <!-- <validation-provider
            #default="{ errors }"
            name="File"
            rules="required"
          >
            <b-form-group
              label="File Arsip"
              label-for="FileArsip"
            >
              <b-form-file
                id="FileArsip"
                ref="file"
                v-model="files"
                type="file"
                placeholder="Tambah File Arsip"
                drop-placeholder="Drop file here..."
                multiple="multiple"
                @change="fileChange"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider> -->

          <h5 class="titlerow mt-1">
            File Arsip
          </h5>
          <b-form-group label-for="FileSurat">
            <b-row
              v-if="gantiFile == false && file !== null"
              class="match-height"
            >
              <b-col
                v-for="item in file"
                :key="item.access_url"
                md="12"
                class="fileSurat"
              >
                <div
                  class="open-file"
                  @click="openFile(item.access_url)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="56"
                  />
                  <h5 class="ml-1">
                    Open File
                    <span> {{ item.real_filename }}{{ item.ext }} </span>
                  </h5>
                </div>
              </b-col>

              <b-button
                v-show="$route.name === 'edit-disposisi'"
                v-model="gantiFile"
                variant="outline-primary"
                class="bg-gradient-primary mt-1 ml-1"
                style="height: 29px"
                size="sm"
                @click.prevent="toggleFile"
              >
                <span class="align-middle">Ganti File</span>
              </b-button>
            </b-row>

            <b-row v-else>
              <b-col md="12">
                <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;">
                  <b-form-file
                    id="FileSurat"
                    ref="file"
                    v-model="file"
                    type="file"
                    placeholder="Input File Surat"
                    drop-placeholder="Drop file here..."
                    multiple="multiple"
                    @change="fileChange"
                  />

                  <b-button
                    v-show="file !== null"
                    v-model="gantiFile"
                    size="sm"
                    variant="outline-primary"
                    class="bg-gradient-primary mt-1"
                    @click.prevent="toggleFile"
                  >
                    <span class="align-middle">Open File</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form-group>

        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="FileSurat"
            rules="required"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >
              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Deskripsi"
            class="mt-1"
          >
            <label for="textarea-default">Deskripsi</label>
            <b-form-textarea
              id="textarea-default"
              v-model="Deskripsi"
              placeholder="Tulis Deskripsi"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

      </b-row>

      <!-- <b-button
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        type="submit"
        @click.prevent="validationForm"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span class="align-middle">Simpan</span>
      </b-button> -->

    </b-card-code>

    <b-row class="match-height">
      <b-col md="7">
        <b-card-code
          title="Status Arsip"
          style="max-height: 200px;"
        >
          <v-select
            v-model="status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :disabled="!authorize"
            placeholder="Pilih Status"
            :options="[{value: 1, text: 'Open' }, {value: 2, text: 'Close' }]"
            class="mb-2"
          />
          <b-button
            v-b-modal.modal-primary
            :disabled="status.length === 0 || !authorize"
            variant="outline-primary"
            class="bg-gradient-primary mt-2"
            type="submit"
            @click.prevent="validationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span class="align-middle">Simpan</span>
          </b-button>
        </b-card-code>
      </b-col>

      <b-col md="5">
        <b-card-code
          title="Riwayat Arsip"
          class="activity"
        >
          <app-timeline
            v-for="data in activity"
            :key="data.id"
          >
            <app-timeline-item
              :title="data.user.name"
              :subtitle="data.message"
              :time="dateFormat(data.updated_at)"
              variant="info"
              style="padding-bottom:20px"
            />
          </app-timeline>
        </b-card-code>
      </b-col>
    </b-row>

  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import store from '@/store/index'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    ToastificationContent,
    vSelect,
    BFormDatepicker,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      NoSurat: '',
      authorize: false,
      gantiFile: false,
      userRole: '',
      selectedRetention: [],
      Subjek: '',
      to: '',
      note: '',
      file: [],
      activity: [],
      Lokasi: '',
      Deskripsi: '',
      Kategori: '',
      selectedKategori: [],
      Waktu: null,
      Deadline: null,
      ReceivedDate: null,
      status: [],
      tags: [],
      optionsKategori: [
        { value: '', text: 'Pilih Kategori' },
        { value: 'Undangan', text: 'Undangan' },
        { value: 'Permohonan', text: 'Permohonan' },
        { value: 'CSR', text: 'CSR' },
        { value: 'Instansi Pendidikan', text: 'Instansi Pendidikan' },
        { value: 'Pemberitahuan', text: 'Pemberitahuan' },
        { value: 'Lain-lain', text: 'Lain-lain' },
      ],
    }
  },
  created() {
    this.authorize = store.state.userData.data.is_admin === 1
    this.userRole = store.state.userData.data.role.name
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addDispo()
        }
      })
    },

    toggleFile() {
      if (this.gantiFile === false) {
        this.gantiFile = true
      } else {
        this.gantiFile = false
      }
    },

    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'Archive',
        },
      })
      this.file = data
    },

    async getActivity() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(`siap/archive/activity/${param}`)
      this.activity = data.data
    },

    async getDetail() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(`/siap/archive/detail/${param}`)
      this.Kategori = data.category.name
      this.NoSurat = data.ref_num
      this.Subjek = data.title
      this.Deskripsi = data.desc
      this.note = data.note
      this.Lokasi = data.location
      this.ReceivedDate = data.received_date
      this.Waktu = data.start_date
      this.Deadline = data.deadline_date
      this.status = {
        value: data.status.id,
        text: data.status.name,
      }
      this.file = data.file
      this.tags = data.tag.map(e => e.name)
      this.getActivity()
    },

    openFile(e) {
      window.open(e, '_blank')
    },

    dateFormat(time) {
      const formatted = dayjs(time).format('DD/MM/YYYY (HH:mm)')
      if (formatted === 'Invalid Date') {
        return '-'
      }
      return formatted
    },

    async addDispo() {
      const param = this.$route.params.pathMatch
      await axios
        .post(`siap/archive/update/${param}`, {
          cat: this.Kategori,
          mail_no: this.NoSurat,
          title: this.Subjek,
          desc: this.Deskripsi,
          note: this.note,
          location: this.Lokasi,
          received_date: dayjs(this.ReceivedDate).format(
            'YYYY-MM-DDTHH:mm:ss+07:00',
          ),
          start_date: dayjs(this.Waktu).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          deadline_date: dayjs(this.Deadline).format(
            'YYYY-MM-DDTHH:mm:ss+07:00',
          ),
          status: this.status.text,
          file: this.file,
          tags: this.tags,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          // window.location.href = `detail-surat-keluar/${response.data.data.id}`
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}
.tanggapan2 {
  display: flex;
  align-items: center;
  justify-content: end;
  .avatar {
    width: 80px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
  }
  h4 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .input {
    margin-left: 25px;
    flex-grow: 1;
  }
  .input-group-append {
    cursor: pointer;
  }
}

.warning-right {
  font-size: 11px;
  color: orange;
  position: absolute;
  top: 0;
  right: 15px;
}

.activity {
  .card-body {
    max-height: 400px;
    overflow-y: scroll;
  }
}

.fileSurat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #a6a6a6;
    display: block;
    margin-top: 5px;
    font-size: 12px;
  }
}

.open-file {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
  transition: background-color 0.5s ease;
  &:hover {
    background: #efefef;
  }
}
</style>
